<template>
  <main class="overflow-hidden">
    <div
      v-if="isAppLinksDebugMode"
      class="fixed flex z-50 bottom-0 left-0 right-0 bg-v-ui-root-monochrome-0 items-center justify-center p-2 gap-2"
    >
      <a
        class="flex justify-center items-center h-8 bg-v-ui-root-accent text-v-ui-text-monochrome-0 w-full px-2 rounded"
        href="doctis://www.doctis.ru/"
      >
        {{ $t('pages.to-application') }}</a
      >
      <a
        class="flex justify-center items-center h-8 bg-v-ui-root-main text-v-ui-text-monochrome-0 w-full px-2 rounded"
        href="doctis://www.doctis.ru/doctors/alfimova-marina"
      >
        {{ $t('pages.to-doctor-page') }}</a
      >
    </div>

    <section class="container flex my-14 xl:relative xl:z-10 xl:mb-0">
      <index-page-slider
        :isShadow="true"
        :is-mobile-device="isMobileDevice"
        :slides="mainSlides"
      >
        <template #title>
          <index-title />
        </template>

        <template #slide="{ slide }">
          <mobile-slide-title
            v-if="isMobileDevice"
            :title="slide.title"
            :color="slide.color"
          />
          <component
            :is="slide.component"
            :is-mobile-device="isMobileDevice"
            :slide="slide"
          />
        </template>
      </index-page-slider>
    </section>

    <section class="flex my-7 relative xl:my-0 xl:bottom-10">
      <index-page-card
        class="xl:py-9 py-8"
        :is-mobile-device="isMobileDevice"
        :bg-image="analysisBg"
        :lazy="false"
      >
        <div class="container relative">
          <index-page-slider
            :slides="analysisSlides"
            :is-mobile-device="isMobileDevice"
            reverse
          >
            <template #title>
              <analysis-title />
            </template>

            <template #slide="{ slide }">
              <component
                :is="slide.component"
                v-if="isMounted"
                :is-mobile-device="isMobileDevice"
                :slide="slide"
              />
            </template>
          </index-page-slider>
        </div>
      </index-page-card>
    </section>

    <section
      class="container flex xl:my-0 my-8 xl:relative xl:z-10 xl:bottom-0"
    >
      <index-page-slider
        :is-mobile-device="isMobileDevice"
        :slides="consultationSlides"
        :without-button="true"
      >
        <template #title>
          <consultation-title />
        </template>

        <template #slide="{ slide }">
          <component :is="slide.component" :slide="slide" />
        </template>
      </index-page-slider>
    </section>

    <section class="flex my-7 relative xl:my-0 xl:bottom-4">
      <index-page-card
        class="py-8 -scale-x-100 bg-cover bg-[40%_center] xl:scale-x-100 xl:flex xl:items-center"
        :is-mobile-device="isMobileDevice"
        :bg-image="commercialBg"
      >
        <div
          class="container -scale-x-100 xl:scale-x-100 xl:flex xl:justify-end"
        >
          <commercial-title />
        </div>
      </index-page-card>
    </section>

    <section class="flex container xl:relative xl:justify-center">
      <client-only>
        <b-banner-slider type="landing" :is-mobile="isMobileDevice" />
      </client-only>
    </section>

    <section class="flex container xl:relative xl:py-[76px] py-10">
      <questions-collapse :items="$t('pages.question-items')" />
    </section>

    <section class="flex relative xl:pb-0 pb-24 xl:bottom-0 bottom-10">
      <index-page-card
        class="py-12 -scale-x-100 h-[35rem] bg-cover bg-[center] xl:scale-x-100 xl:flex xl:items-center"
        :is-mobile-device="isMobileDevice"
        :bg-image="subscriptionBg"
      >
        <div
          class="container lg:p-0 -scale-x-100 xl:scale-x-100 xl:flex xl:items-center xl:justify-between xl:gap-16"
        >
          <subscription-title />
          <client-only>
            <b-subscription-form class="flex-1" />
          </client-only>
        </div>
      </index-page-card>
    </section>
  </main>
</template>

<script>
import IndexPageSlider from '@/components/pages/index/index-page-slider/IndexPageSlider';
import ConsultationSlide from '@/components/pages/index/main/ConsultationSlide';
import AnalysisSlide from '@/components/pages/index/main/AnalysisSlide';
import MobileSlideTitle from '@/components/pages/index/main/MobileSlideTitle';
import AnalysisTitle from '@/components/pages/index/analysis/AnalysisTitle';
import IndexPageCard from '@/components/pages/index/index-page-card/IndexPageCard';
import AnalysisMapSlide from '@/components/pages/index/analysis/AnalysisMapSlide';
import IndexTitle from '@/components/pages/index/main/IndexTitle';

export default {
  components: {
    IndexPageCard,
    IndexPageSlider,

    IndexTitle,
    MobileSlideTitle,

    ConsultationSlide,
    AnalysisSlide,

    AnalysisTitle,
    AnalysisMapSlide,

    ConsultationVideoSlide: () =>
      import('@/components/pages/index/consultation/ConsultationVideoSlide'),
    ConsultationTitle: () =>
      import('@/components/pages/index/consultation/ConsultationTitle'),

    CommercialTitle: () =>
      import('@/components/pages/index/commercial/CommercialTitle'),

    BBannerSlider: () =>
      import('@/components/business/promotion/BBannerSlider'),

    QuestionsCollapse: () =>
      import('@/components/pages/index/questions/QuestionsCollapse'),

    BSubscriptionForm: () =>
      import('@/components/business/subscription/BSubscriptionForm'),
    SubscriptionTitle: () =>
      import('@/components/pages/index/subscription/SubscriptionTitle')
  },
  layout: 'main',
  auth: false,
  data() {
    return {
      isMobileDevice: true,
      isMounted: false,
      mainSlides: [
        {
          component: 'consultation-slide',
          title: this.$t('pages.mainSlides[0].title'),
          titleShort: this.$t('pages.mainSlides[0].titleShort'),
          color: 'bg-v-ui-root-main-alt',
          items: [
            {
              specialization: this.$t(
                'pages.mainSlides[0].items[0].specialization'
              ),
              description: this.$t('pages.mainSlides[0].items[0].description'),
              time: '11:25',
              active: true,
              doctor: {
                firstName: this.$t(
                  'pages.mainSlides[0].items[0].doctor.firstName'
                ),
                lastName: this.$t(
                  'pages.mainSlides[0].items[0].doctor.lastName'
                ),
                middleName: this.$t(
                  'pages.mainSlides[0].items[0].doctor.middleName'
                ),
                avatar: '/images/pages/index/lodit.webp'
              }
            },
            {
              specialization: this.$t(
                'pages.mainSlides[0].items[1].specialization'
              ),
              description: this.$t('pages.mainSlides[0].items[1].description'),
              active: false,
              time: '12:35',
              doctor: {
                firstName: this.$t(
                  'pages.mainSlides[0].items[1].doctor.firstName'
                ),
                lastName: this.$t(
                  'pages.mainSlides[0].items[1].doctor.lastName'
                ),
                middleName: this.$t(
                  'pages.mainSlides[0].items[1].doctor.middleName'
                ),
                avatar: '/images/pages/index/koroleva.webp'
              }
            },
            {
              specialization: this.$t(
                'pages.mainSlides[0].items[2].specialization'
              ),
              description: this.$t('pages.mainSlides[0].items[2].description'),
              time: '18:15',
              active: true,
              doctor: {
                firstName: this.$t(
                  'pages.mainSlides[0].items[2].doctor.firstName'
                ),
                lastName: this.$t(
                  'pages.mainSlides[0].items[2].doctor.lastName'
                ),
                middleName: this.$t(
                  'pages.mainSlides[0].items[2].doctor.middleName'
                ),
                avatar: '/images/pages/index/mudunov.webp'
              }
            }
          ]
        },
        {
          component: 'analysis-slide',
          title: this.$t('pages.mainSlides[1].title'),
          titleShort: this.$t('pages.mainSlides[1].titleShort'),
          color: 'bg-v-ui-root-accent-alt',
          items: [
            {
              title: this.$t('pages.mainSlides[1].items[0].title'),
              active: true,
              label: this.$t('pages.mainSlides[1].items[0].label')
            },
            {
              title: this.$t('pages.mainSlides[1].items[1].title'),
              active: false,
              label: this.$t('pages.mainSlides[1].items[1].label')
            },
            {
              title: this.$t('pages.mainSlides[1].items[2].title'),
              active: false,
              label: this.$t('pages.mainSlides[1].items[2].label')
            }
          ]
        }
      ],
      analysisSlides: [
        {
          component: 'analysis-map-slide',
          title: this.$t('pages.analysis-slides.title'),
          titleShort: this.$t('pages.analysis-slides.title-short'),
          link: '/analysis'
        }
      ],

      consultationSlides: [
        {
          component: 'consultation-video-slide',
          title: this.$t('pages.consultation-slides.title'),
          titleShort: this.$t('pages.consultation-slides.title-short')
        }
      ],
      analysisBg: '/images/pages/index/analysis-bg.webp',

      commercialBg: '/images/pages/index/commercial-bg.webp',

      subscriptionBg: '/images/pages/index/subscription-bg.webp'
    };
  },
  fetch() {
    this.isMobileDevice = this.$device.isMobileOrTablet;
  },
  head() {
    return {
      title: this.$t('pages.seo.title'),
      meta: [
        {
          name: 'description',
          content: this.$t('pages.seo.description')
        }
      ]
    };
  },
  computed: {
    isAppLinksDebugMode() {
      return this.$route.query.debug === 'app-links';
    }
  },
  mounted() {
    this.$amplitude.logEvent('open_screen', {
      screen_name: 'Главная страница'
    });
    if (window) {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }

    this.isMounted = true;

    if (this.$route.query?.authorization) {
      this.$authService.startAuthorizationFlow();
    }
  },
  methods: {
    onResize() {
      this.isMobileDevice = document.documentElement.clientWidth < 1140;
    }
  }
};
</script>
